import { RootStateD } from "@/redux-toolkit/store";
import { localStorageKeys } from "@/utils/constants/localStorageKeys";
import { getLocalStorageItem } from "@/utils/helperFunctions/localStorage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommentEventD } from "../comments/commentsTypes";
import { DashboardDataD, DashboardthemeD, PageD } from "./mokkupBuilderTypes";
import { FrameworkTypeE } from "@/views/mokkupBuilder/utils/types";
import { StatusTypeD } from "@/views/mokkupBuilder/utils/helperComponents/ExportStatusToast";

type DropPositionD = {
  x: number;
  y: number;
};

export type ImageExportDataD = {
  type: {
    format: "png" | "jpeg" | "pdf";
    scope: "project" | "screen";
  };
  taskId: string;
  status: StatusTypeD;
  startPolling: boolean;
  isToastDismissed: boolean;
};

type LayoutStateD = {
  state: boolean;
  openThreads: boolean;
  isManualClose: boolean;
  dashboardName: string;
  updateScreenName: boolean;
  active: string;
  deleteId: number;
  saveText: boolean;
  restrict: boolean;
  isEmpty: boolean;
  role: string | null;
  roleLoading: boolean;
  roleError: string;
  updatePages: boolean;
  updateElements: boolean;
  dropPosition: DropPositionD;
  addTitle: boolean;
  getData: boolean;
  updateName: boolean;
  saveImage: boolean;
  confirm: boolean;
  projectName: string;
  currentscreenName: string;
  pageData: PageD[];
  boardWrapperWidth: number;
  canvasZoom: number;
  activeScreen: number | undefined | "";
  // activeScreen: { screen_id: string; screen_name: string } | null;
  isRenamed: boolean;
  activeTabs: number | undefined;
  run: boolean;
  saveContent: boolean;
  upgradeView: string | null;
  boardWidth: number;
  boardHeight: number;
  boardWrapperHeight: number;
  autoZoomValue: number;
  zoomRestrictValue: number;
  onResize: boolean;
  openShare: boolean;
  undoDisabled: boolean;
  autoSaveError: boolean;
  redoDisabled: boolean;
  activeTab: string;
  callCommentEvents: CommentEventD | null;
  screenTabs: string;
  activeFrame: boolean;
  loader: boolean;
  openCommentView: boolean;
  commentMode: string;
  dict: boolean;
  dashboardTheme: DashboardthemeD | undefined;
  activePageIdName: { id: number; name: string };
  isEmbed: { is_publish: boolean; token?: string } | undefined;
  shouldReconnectSocket: boolean;
  dashboardData: DashboardDataD | undefined;
  manualSaveSuccess: boolean;
  isCustomised: boolean;
  frameworkType: FrameworkTypeE;
  showPersistModal: boolean;
  imageZoom: number | null;
  imageExportData: ImageExportDataD[];
  shareActiveTab : string | null;
};

const initialState: LayoutStateD = {
  state: false,
  openThreads: false,
  isManualClose: false,
  dashboardName: getLocalStorageItem(localStorageKeys.DASHBOARD_NAME) || "",
  updateScreenName: false,
  active: "",
  deleteId: 0,
  saveText: false,
  restrict: true,
  isEmpty: true,
  role: null,
  roleLoading: false,
  roleError: "",
  updatePages: true,
  updateElements: false,
  dropPosition: { x: 0, y: 0 },
  addTitle: false,
  getData: true,
  updateName: false,
  saveImage: false,
  confirm: false,
  projectName: "",
  currentscreenName: "",
  pageData: [],
  boardWrapperWidth: 1,
  canvasZoom: 1,
  activeScreen: undefined,
  isRenamed: false,
  activeTabs: undefined,
  run: false,
  saveContent: false,
  upgradeView: null,
  boardWidth: 1280,
  boardHeight: 720,
  boardWrapperHeight: 1,
  autoZoomValue: 1,
  zoomRestrictValue: 0,
  onResize: false,
  openShare: false,
  undoDisabled: false,
  autoSaveError: false,
  redoDisabled: false,
  activeTab: "1",
  callCommentEvents: null,
  screenTabs: "1",
  activeFrame: false,
  loader: false,
  openCommentView: false,
  commentMode: "general",
  dict: false,
  dashboardTheme: undefined,
  activePageIdName: { id: 0, name: "" },
  isEmbed: undefined,
  shouldReconnectSocket: false,
  dashboardData: undefined,
  manualSaveSuccess: false,
  isCustomised: false,
  frameworkType: FrameworkTypeE.FLOW,
  showPersistModal: false,
  imageZoom: null,
  imageExportData: [] as ImageExportDataD[],
  shareActiveTab : null
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    resetLayoutState: () => initialState,
    setState: (state, action: PayloadAction<boolean>) => {
      state.state = action.payload;
    },
    setOpenThreads: (state, action: PayloadAction<boolean>) => {
      state.openThreads = action.payload;
    },
    setIsManualClose: (state, action: PayloadAction<boolean>) => {
      state.isManualClose = action.payload;
    },
    setDashboardName: (state, action: PayloadAction<string>) => {
      state.dashboardName = action.payload;
    },
    setUpdateScreenName: (state, action: PayloadAction<boolean>) => {
      state.updateScreenName = action.payload;
    },
    setActive: (state, action: PayloadAction<string>) => {
      state.active = action.payload;
    },
    setDeleteId: (state, action: PayloadAction<number>) => {
      state.deleteId = action.payload;
    },
    setSaveText: (state, action: PayloadAction<boolean>) => {
      state.saveText = action.payload;
    },
    setRestrict: (state, action: PayloadAction<boolean>) => {
      state.restrict = action.payload;
    },
    setIsEmpty: (state, action: PayloadAction<boolean>) => {
      state.isEmpty = action.payload;
    },
    setRole: (state, action: PayloadAction<string | null>) => {
      state.role = action.payload;
    },
    setRoleLoading: (state, action: PayloadAction<boolean>) => {
      state.roleLoading = action.payload;
    },
    setRoleError: (state, action: PayloadAction<string>) => {
      state.roleError = action.payload;
    },
    setUpdatePages: (state, action: PayloadAction<boolean>) => {
      state.updatePages = action.payload;
    },
    setUpdateElements: (state, action: PayloadAction<boolean>) => {
      state.updateElements = action.payload;
    },
    setDropPosition: (state, action: PayloadAction<DropPositionD>) => {
      state.dropPosition = action.payload;
    },
    setAddTitle: (state, action: PayloadAction<boolean>) => {
      state.addTitle = action.payload;
    },
    setGetData: (state, action: PayloadAction<boolean>) => {
      state.getData = action.payload;
    },
    setUpdateName: (state, action: PayloadAction<boolean>) => {
      state.updateName = action.payload;
    },
    setSaveImage: (state, action: PayloadAction<boolean>) => {
      state.saveImage = action.payload;
    },
    setConfirm: (state, action: PayloadAction<boolean>) => {
      state.confirm = action.payload;
    },
    setProjectName: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
    },
    setCurrentscreenName: (state, action: PayloadAction<string>) => {
      state.currentscreenName = action.payload;
    },
    setPageData: (state, action: PayloadAction<PageD[]>) => {
      state.pageData = action.payload;
    },
    setBoardWrapperWidth: (state, action: PayloadAction<number>) => {
      state.boardWrapperWidth = action.payload;
    },
    setCanvasZoom: (state, action: PayloadAction<number>) => {
      state.canvasZoom = action.payload;
    },
    setActiveScreen: (state, action: PayloadAction<number | "">) => {
      state.activeScreen = action.payload;
    },
    setIsRenamed: (state, action: PayloadAction<boolean>) => {
      state.isRenamed = action.payload;
    },
    setActiveTabs: (state, action: PayloadAction<number | undefined>) => {
      state.activeTabs = action.payload;
    },
    setRun: (state, action: PayloadAction<boolean>) => {
      state.run = action.payload;
    },
    setSaveContent: (state, action: PayloadAction<boolean>) => {
      state.saveContent = action.payload;
    },
    setUpgradeView: (state, action: PayloadAction<string | null>) => {
      state.upgradeView = action.payload;
    },
    setBoardWidth: (state, action: PayloadAction<number>) => {
      state.boardWidth = action.payload;
    },
    setBoardHeight: (state, action: PayloadAction<number>) => {
      state.boardHeight = action.payload;
    },
    setBoardWrapperHeight: (state, action: PayloadAction<number>) => {
      state.boardWrapperHeight = action.payload;
    },
    setAutoZoomValue: (state, action: PayloadAction<number>) => {
      state.autoZoomValue = action.payload;
    },
    setZoomRestrictValue: (state, action: PayloadAction<number>) => {
      state.zoomRestrictValue = action.payload;
    },
    setOnResize: (state, action: PayloadAction<boolean>) => {
      state.onResize = action.payload;
    },
    setOpenShare: (state, action: PayloadAction<boolean>) => {
      state.openShare = action.payload;
    },
    setUndoDisabled: (state, action: PayloadAction<boolean>) => {
      state.undoDisabled = action.payload;
    },
    setAutoSaveError: (state, action: PayloadAction<boolean>) => {
      state.autoSaveError = action.payload;
    },
    setRedoDisabled: (state, action: PayloadAction<boolean>) => {
      state.redoDisabled = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setCallCommentEvents: (state, action: PayloadAction<CommentEventD>) => {
      state.callCommentEvents = action.payload;
    },
    setScreenTabs: (state, action: PayloadAction<string>) => {
      state.screenTabs = action.payload;
    },
    setActiveFrame: (state, action: PayloadAction<boolean>) => {
      state.activeFrame = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setOpenCommentView: (state, action: PayloadAction<boolean>) => {
      state.openCommentView = action.payload;
    },
    setCommentMode: (state, action: PayloadAction<string>) => {
      state.commentMode = action.payload;
    },
    setDict: (state, action: PayloadAction<boolean>) => {
      state.dict = action.payload;
    },
    setDashboardTheme: (
      state,
      action: PayloadAction<DashboardthemeD | undefined>
    ) => {
      state.dashboardTheme = action.payload;
    },
    setActivePageIdName: (
      state,
      action: PayloadAction<{ id: number; name: string }>
    ) => {
      state.activePageIdName = action.payload;
    },
    setIsEmbed: (
      state,
      action: PayloadAction<{ is_publish: boolean; token?: string } | undefined>
    ) => {
      state.isEmbed = action.payload;
    },
    setShouldReconnectSocket: (state, action: PayloadAction<boolean>) => {
      state.shouldReconnectSocket = action.payload;
    },
    setDashboardData: (state, action: PayloadAction<DashboardDataD>) => {
      state.dashboardData = action.payload;
    },
    setManualSaveSuccess: (state, action: PayloadAction<boolean>) => {
      state.manualSaveSuccess = action.payload;
    },
    setIsCustomised: (state, action: PayloadAction<boolean>) => {
      state.isCustomised = action.payload;
    },
    setFrameworkType: (state, action: PayloadAction<FrameworkTypeE>) => {
      state.frameworkType = action.payload;
    },
    setShowPersistModal: (state, action: PayloadAction<boolean>) => {
      state.showPersistModal = action.payload;
    },
    setImageZoom: (state, action: PayloadAction<number | null>) => {
      state.imageZoom = action.payload;
    },
    setImageExportData: (state, action: PayloadAction<ImageExportDataD>) => {
      if (!state.imageExportData) {
        state.imageExportData = [action.payload];
      } else {
        const existingIndex = state.imageExportData.findIndex(
          (item) => item.taskId === action.payload.taskId
        );
        if (existingIndex >= 0) {
          state.imageExportData[existingIndex] = action.payload;
        } else {
          state.imageExportData.push(action.payload);
        }
      }
    },
    setImageExportStatus: (
      state,
      action: PayloadAction<{
        taskId: string;
        status: ImageExportDataD["status"];
      }>
    ) => {
      const { taskId, status } = action.payload;
      const index = state.imageExportData?.findIndex(
        (item) => item.taskId === taskId
      );
      if (index !== undefined && state.imageExportData) {
        state.imageExportData[index].status = status;
      }
    },
    setImageExportFormat: (
      state,
      action: PayloadAction<{
        taskId: string;
        format: ImageExportDataD["type"]["format"];
      }>
    ) => {
      const { taskId, format } = action.payload;
      const index = state.imageExportData?.findIndex(
        (item) => item.taskId === taskId
      );
      if (index !== undefined && state.imageExportData) {
        state.imageExportData[index].type.format = format;
      }
    },
    setImageExportScope: (
      state,
      action: PayloadAction<{
        taskId: string;
        scope: ImageExportDataD["type"]["scope"];
      }>
    ) => {
      const { taskId, scope } = action.payload;
      const index = state.imageExportData?.findIndex(
        (item) => item.taskId === taskId
      );
      if (index !== undefined && state.imageExportData) {
        state.imageExportData[index].type.scope = scope;
      }
    },
    removeImageExportTask: (state, action: PayloadAction<string>) => {
      state.imageExportData =
        state.imageExportData?.filter(
          (item) => item.taskId !== action.payload
        ) ?? null;
    },
    setImagePolling: (
      state,
      action: PayloadAction<{ taskId: string; polling: boolean }>
    ) => {
      const { taskId, polling } = action.payload;
      const index = state.imageExportData?.findIndex(
        (item) => item.taskId === taskId
      );
      if (index !== undefined && state.imageExportData) {
        state.imageExportData[index].startPolling = polling;
      }
    },
    disableExportToast: (state, action: PayloadAction<{ taskId: string }>) => {
      const { taskId } = action.payload;
      const index = state.imageExportData?.findIndex(
        (item) => item.taskId === taskId
      );
      if (index !== undefined && state.imageExportData) {
        state.imageExportData[index].isToastDismissed = true;
      }
    },
    setShareActiveTab : (state, action : PayloadAction<string>) => {
      const tabId  = action.payload;
      state.shareActiveTab = tabId
    }
  },
});

export const {
  resetLayoutState,
  setState,
  setOpenThreads,
  setIsManualClose,
  setDashboardName,
  setUpdateScreenName,
  setActive,
  setDeleteId,
  setSaveText,
  setRestrict,
  setIsEmpty,
  setRole,
  setRoleLoading,
  setRoleError,
  setUpdatePages,
  setUpdateElements,
  setDropPosition,
  setAddTitle,
  setGetData,
  setUpdateName,
  setSaveImage,
  setConfirm,
  setProjectName,
  setCurrentscreenName,
  setPageData,
  setBoardWrapperWidth,
  setActiveScreen,
  setIsRenamed,
  setActiveTabs,
  setRun,
  setSaveContent,
  setUpgradeView,
  setBoardWidth,
  setBoardHeight,
  setBoardWrapperHeight,
  setCanvasZoom,
  setAutoZoomValue,
  setZoomRestrictValue,
  setOnResize,
  setOpenShare,
  setUndoDisabled,
  setAutoSaveError,
  setRedoDisabled,
  setActiveTab,
  setCallCommentEvents,
  setScreenTabs,
  setActiveFrame,
  setLoader,
  setOpenCommentView,
  setCommentMode,
  setDict,
  setDashboardTheme,
  setActivePageIdName,
  setIsEmbed,
  setShouldReconnectSocket,
  setDashboardData,
  setManualSaveSuccess,
  setIsCustomised,
  setFrameworkType,
  setShowPersistModal,
  setImageZoom,
  setImageExportData,
  setImageExportStatus,
  setImageExportFormat,
  setImageExportScope,
  removeImageExportTask,
  setImagePolling,
  disableExportToast,
  setShareActiveTab
} = layoutSlice.actions;

export const selectLayout = (state: RootStateD) => state.builderLayout;

export default layoutSlice.reducer;
