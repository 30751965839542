import {
  CustomisationsD,
  FutureLayout,
  LayoutItemD,
  PageD,
  PastLayoutD,
} from "@/redux-toolkit/features/mokkupBuilder/mokkupBuilderTypes";
import { getLocalStorageItem } from "@/utils/helperFunctions/localStorage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { ydoc } from "@/utils/yjsSetup";
// import * as Y from "yjs";

export type BuilderStateD = {
  layouts: LayoutItemD[];
  pages: PageD[];
  pageImages: { [key: number]: string };
  pastLayout: PastLayoutD;
  futureLayout: FutureLayout;
  customisations: CustomisationsD;
  canvasStyleCustomisation: {};
  elementStyleCustomisation: {};
  elementWidth: string | null;
  dynamicRowHeight: number;
};

type ProjectInfoD = {
  projectId: number;
  screenId: number;
  modified_at: string;
};

export const initialBuilderState: BuilderStateD = {
  layouts: [],
  pages: [],
  pageImages: {},
  pastLayout: [],
  futureLayout: [],
  customisations: {} as CustomisationsD,
  canvasStyleCustomisation: {},
  elementStyleCustomisation: {},
  elementWidth: null,
  dynamicRowHeight: 10,
};

const builderSlice = createSlice({
  name: "builder",
  initialState: initialBuilderState,
  reducers: {
    resetBuilderState: (state) => ({
      ...initialBuilderState,
    }),
    setBuilderState: (state, action: PayloadAction<BuilderStateD>) => {
      state = action.payload;
    },
    updateLayout: (state, action: PayloadAction<LayoutItemD[]>) => {
      const filteredLayouts = action.payload.filter(
        (layout) => layout?.data?.i !== "__dropping-elem__"
      );
      const addPastArray = state.pastLayout;
      let activeScreenId = getLocalStorageItem("currentScreenId");
      const existingPastLayoutItem = addPastArray.find(
        (ele) => ele.id == activeScreenId
      );
      if (existingPastLayoutItem) {
        addPastArray.forEach((item) => {
          if (item.id == activeScreenId) {
            item.layout.push(
              state.layouts.filter(
                (layout) => layout?.data?.i !== "__dropping-elem__"
              )
            );
          }
        });
      } else {
        if (activeScreenId)
          addPastArray.push({
            id: activeScreenId,
            layout: [
              state.layouts.filter(
                (layout) => layout?.data?.i !== "__dropping-elem__"
              ),
            ],
          });
      }

      state.layouts = filteredLayouts;
      state.pastLayout = addPastArray;
      state.futureLayout = [];
    },
    addLayoutElement: (state, action: PayloadAction<LayoutItemD>) => {
      const filteredLayouts = [...state.layouts, action.payload];
      const addPastArray = state.pastLayout;
      let activeScreenId = getLocalStorageItem("currentScreenId");
      const existingPastLayoutItem = addPastArray.find(
        (ele) => ele.id == activeScreenId
      );
      if (existingPastLayoutItem) {
        addPastArray.forEach((item) => {
          if (item.id == activeScreenId) {
            item.layout.push(
              state.layouts.filter(
                (layout) => layout?.data?.i !== "__dropping-elem__"
              )
            );
          }
        });
      } else {
        if (activeScreenId)
          addPastArray.push({
            id: activeScreenId,
            layout: [
              state.layouts.filter(
                (layout) => layout?.data?.i !== "__dropping-elem__"
              ),
            ],
          });
      }

      state.layouts = filteredLayouts;
      state.pastLayout = addPastArray;
      state.futureLayout = [];
    },
    updateLayoutNode: (
      state,
      action: PayloadAction<{
        id: string;
        position?: { x: number; y: number };
        width?: number;
        height?: number;
      }>
    ) => {
      const layout = state.layouts?.find(
        (item) => item?.data?.i === action.payload.id
      );

      if (layout) {
        if (action.payload.position) {
          layout.position = action.payload.position;
          layout.data.x = action.payload.position.x;
          layout.data.y = action.payload.position.y;
          layout.data.metaData.size = {
            ...layout.data.metaData.size,
            x: action.payload.position.x,
            y: action.payload.position.y,
          };
        }
        if (action.payload.width && action.payload.height) {
          layout.width = action.payload.width;
          layout.height = action.payload.height;
          (layout.measured = {
            width: action.payload.width,
            height: action.payload.height,
          }),
            (layout.data.w = action.payload.width);
          layout.data.h = action.payload.height;
          layout.data.metaData.size = {
            ...layout.data.metaData.size,
            w: action.payload.width,
            h: action.payload.height,
          };
        }
      }
    },
    setLayouts: (
      state,
      action: PayloadAction<{ layouts: LayoutItemD[]; setTimeStamp?: boolean }>
    ) => {
      state.layouts = [...action.payload.layouts];

      // YJS SETUP
      // const yLayouts = ydoc.getArray("layouts");
      // yLayouts.delete(0, yLayouts.length);
      // yLayouts.push(action.payload);
    },
    setPages: (state, action: PayloadAction<PageD[]>) => {
      state.pages = action.payload;
    },
    setPageImage: (
      state,
      action: PayloadAction<{ id: number; image: string }>
    ) => {
      state.pages.forEach((page) => {
        if (page.id == action.payload.id) {
          page.image = action.payload.image;
        }
      });
    },
    setPageImageById: (
      state,
      action: PayloadAction<{ id: number; image: string }>
    ) => {
      state.pageImages[action.payload.id] = action.payload.image;
    },

    setPageDimensions: (
      state,
      action: PayloadAction<{ width: number; height: number; id: number }>
    ) => {
      state.pages.forEach((page) => {
        if (page.id == action.payload.id) {
          page.width = action.payload.width;
          page.height = action.payload.height;
        }
      });
    },
    undo: (state) => {
      const newFutureLayout = [...state.futureLayout];
      const activeScreen = getLocalStorageItem("currentScreenId") as string;
      const existingFutureLayout = newFutureLayout.find(
        (ele) => ele.id == activeScreen
      );
      if (existingFutureLayout) {
        newFutureLayout.forEach((item) => {
          if (item.id == activeScreen) {
            item.layout.push([...state.layouts]);
          }
        });
      } else {
        newFutureLayout.push({
          id: activeScreen,
          layout: [[...state.layouts]],
        });
      }
      const newPast = [...state.pastLayout];
      const existingPastLayout = newPast.find((ele) => ele.id == activeScreen);
      let newPresent: LayoutItemD[] = [];
      if (existingPastLayout) {
        newPresent =
          existingPastLayout.layout[existingPastLayout.layout.length - 1];
        newPast.forEach((item) => {
          if (item.id == activeScreen) {
            item.layout.pop();
          }
        });
      }
      state.pastLayout = newPast;
      state.layouts = newPresent;
      state.futureLayout = newFutureLayout;
    },
    redo: (state) => {
      const currentScreen = getLocalStorageItem("currentScreenId") as string;
      const currentFutureLayout = [...state.futureLayout];
      const currentFutureLayoutItem = currentFutureLayout.find(
        (ele) => ele.id == currentScreen
      );
      let nextPresent: LayoutItemD[] = [];
      if (currentFutureLayoutItem) {
        nextPresent =
          currentFutureLayoutItem.layout[
            currentFutureLayoutItem.layout.length - 1
          ];
        currentFutureLayout.forEach((item) => {
          if (item.id == currentScreen) {
            item.layout.pop();
          }
        });
      }
      const newFuture = currentFutureLayout;
      const currentPastLayout = state.pastLayout.find(
        (ele) => ele.id == currentScreen
      );
      const nextPast = [...state.pastLayout];
      if (currentPastLayout) {
        nextPast.forEach((item) => {
          if (item.id == currentScreen) {
            item.layout.push([...state.layouts]);
          }
        });
      }
      state.pastLayout = nextPast;
      state.layouts = nextPresent;
      state.futureLayout = newFuture;
    },
    resetUndo: (state) => {
      state.pastLayout = [];
    },
    resetRedo: (state) => {
      state.futureLayout = [];
    },
    updateCustomisations: (state, action: PayloadAction<CustomisationsD>) => {
      state.customisations = action.payload;
    },
    setCanvasStyleCustomisation: (
      state,
      action: PayloadAction<CustomisationsD>
    ) => {
      state.canvasStyleCustomisation = action.payload;
    },
    setElementStyleCustomisation: (
      state,
      action: PayloadAction<CustomisationsD>
    ) => {
      state.elementStyleCustomisation = action.payload;
    },
    setElementWidth: (state, action: PayloadAction<string>) => {
      state.elementWidth = action.payload;
    },
    setDynamicRowHeight: (state, action: PayloadAction<number>) => {
      state.dynamicRowHeight = action.payload;
    },
  },
});

export const {
  resetBuilderState,
  setBuilderState,
  updateLayout,
  setPages,
  setPageImage,
  setPageImageById,
  undo,
  redo,
  resetUndo,
  resetRedo,
  setLayouts,
  updateCustomisations,
  setCanvasStyleCustomisation,
  setElementStyleCustomisation,
  setElementWidth,
  setDynamicRowHeight,
  updateLayoutNode,
  setPageDimensions,
  addLayoutElement,
} = builderSlice.actions;

export default builderSlice.reducer;
